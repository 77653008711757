<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-1" v-if="!showInviteForm">
      <h3 class="mb-0 mr-1">Convites</h3>
      <b-button variant="primary" @click="showInviteForm = true">
        <span class="mb-0 text-dark font-weight-bolder"> Criar convite </span>
      </b-button>
    </div>

    <invite-form v-else @closeForm="closeInviteForm" />

    <b-card class="card-cs-border card-cs-bg p-1 mb-0" v-if="!showInviteForm && !invites.length">
      <div class="d-flex align-items-center">
        <b-img src="@/assets/images/admin/invites-empty-state.svg" width="100" class="mr-2" />
        <div class="d-flex flex-column">
          <h4>Nenhum convite foi criado até momento.</h4>
          <span>Crie um convite de cadastro agora.</span>
        </div>
      </div>
    </b-card>

    <invite-card
      v-for="invite in invites"
      :key="invite.id"
      class="mt-1"
      :invite="invite"
      @inviteDeleted="getData"
      @inviteResent="getData"
    />
  </div>
</template>

<script>
import { BImg, BCard, BBadge, BButton, BTooltip, VBTooltip } from 'bootstrap-vue'
import InviteForm from '@/views/admin/provider/components/InviteForm.vue'
import InviteCard from '@/views/admin/provider/components/InviteCard.vue'

export default {
  name: 'Invites',
  components: {
    BImg,
    BCard,
    BBadge,
    BButton,
    BTooltip,
    InviteCard,
    InviteForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      invites: [],
      showInviteForm: false,
    }
  },
  async mounted() {
    await this.getData()
    if (this.$route.query.creating) this.showInviteForm = true
  },
  methods: {
    closeInviteForm() {
      this.getData()
      this.showInviteForm = false
    },
    async getData() {
      try {
        const { data } = await this.$store.dispatch(
          'adminProvider/fetchInvites',
          this.$route.params.id,
        )
        this.invites = data.data
      } catch (error) {}
    },
  },
}
</script>
